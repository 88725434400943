import { Box, Stack, Input, Button } from "@mui/material"
import { useEffect, useState } from "react"
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import ButtonComponent from "./Button"
import Candidate from "../client/API/Candidate"
import { useDispatch } from "react-redux"
import { setMessage } from "../store/reducers/message"
import { styled } from '@mui/material/styles'
import juice from 'juice'

const Input1 = styled('input')({
  display: 'none',
})

const fonts = [
  { value: 'sans-serif', label: 'Sans Serif' },
  { value: 'serif', label: 'Serif' },
  { value: 'monospace', label: 'Monospace' },
  { value: 'georgia', label: 'Georgia' },
  // { value: 'times-new-roman', label: 'Time new' },
  { value: 'verdana', label: 'Verdana' },
]

const Quill = ReactQuill.Quill
const Block = Quill.import('blots/block')
class DivBlock extends Block { }
DivBlock.tagName = 'div'
Quill.register(DivBlock)

const Font = Quill.import('formats/font');
Font.whitelist = fonts.map(font => font.value);
Quill.register(Font, true);

export default function Mail({ emails, resetEmails, setExpanded, selectedFile }) {
  const [mailContent, setMailContent] = useState('<div>Hi {{firstName}},</div>')
  const [subject, setSubject] = useState('')
  const [fileData, setFileData] = useState(null)
  const [loading, setLoading] = useState(false)

  const dispatch = useDispatch()

  useEffect(() => {
    const Block = Quill.import('blots/block');
    Block.tagName = 'div';
    Quill.register(Block, true);
  }, [])

  const applyInlineStyles = (htmlContent) => {
    // Add your custom CSS here
    const styles = `
    .ql-font-sans-serif {
      font-family: sans-serif;
    }
    .ql-font-serif {
      font-family: serif;
    }
    .ql-font-monospace {
      font-family: monospace;
    }
    .ql-font-georgia {
      font-family: Georgia, serif;
    }
    .ql-font-times-new-roman {
      font-family: 'Times New Roman', Times, serif;
    }
    .ql-font-verdana {
      font-family: Verdana, sans-serif;
    }
    `;

    const htmlWithStyles = `
      <style>
        ${styles}
      </style>
      ${htmlContent}
    `;

    return juice(htmlWithStyles);
  };

  const sendMail = async () => {
    setLoading(true)
    let recievers = []
    let res = null
    const leftMenu = [{ category: selectedFile?.replace("File-", "Community "), menu: [] }]
    emails.forEach(datum => {
      if (datum.name) {
        const cmpName = datum.name.split('-')[0]
        if (cmpName && leftMenu[0].menu.indexOf(cmpName) === -1) {
          leftMenu[0].menu.push(cmpName)
        }
      }
      datum?.res.forEach(record => {
        recievers = [...recievers, record]
      })
    })
    recievers = recievers.filter(d => d.mailId && d.firstName);
    const candidate = localStorage.getItem('candidate') ? JSON.parse(localStorage.getItem('candidate')) : ''
    if (!mailContent || !subject) {
      setLoading(false)
      return dispatch(setMessage({ type: 'error', text: 'Please fill in subject and content details' }))
    }
    if (!fileData) {
      setLoading(false)
      return dispatch(setMessage({ type: 'error', text: 'Please attach your resume' }))
    }
    if (!recievers.length && !emails.length) {
      setLoading(false)
      return dispatch(setMessage({ type: 'error', text: 'Please select staffing vendors from the left menu' }));
    } else if (!recievers.length) {
      setLoading(false)
      return
    }
    if (fileData) {
      const formData = new FormData()
      formData.append('file', fileData)
      try {
        res = await Candidate.uploadResume(formData)
        if (res === 'Please try again later. If problem persist, please contact the administrator.') {
          throw res;
        }
      } catch (error) {
        dispatch(setMessage({ type: 'error', text: 'Something went wrong!' }))
        setLoading(false)
        return false
      }
    }
    try {
      if (candidate) {
        const emailContent = applyInlineStyles(mailContent);
        const res1 = await Candidate.sendMail({
          candidateMailId: candidate?.user?.email,
          leftMenu,
          recievers,
          fileName: res,
          subject,
          body: emailContent
        })
        if (res1 === 'MAIL_LIMIT_EXCEEDS') {
          dispatch(setMessage({ type: 'error', text: 'Your Weekly limit exceeded!' }))
        } else {
          dispatch(setMessage({
            type: 'success',
            horizontal: 'center',
            vertical: 'top',
            text: `Congratulations! You've successfully shared your resume with chosen vendors for your target clients. Stay tuned for emails, calls, and LinkedIn messages.`
          }))
          resetData()
        }
      }
    } catch (error) {
      return false
    } finally {
      setLoading(false)
    }
  }

  const resetData = () => {
    setMailContent('<div>Hi {{firstName}},</div>')
    setSubject('')
    setFileData(null)
    resetEmails()
    setExpanded([])
  }

  return (
    <Stack direction='column' sx={{
      position: 'absolute', bottom: '0',
      right: '2rem', boxShadow: `0 4px 8px rgba(0, 0, 0, 0.2),
      4px 0 8px rgba(0, 0, 0, 0.2),
      0 -4px 8px rgba(0, 0, 0, 0.2),
      -4px 0 8px rgba(0, 0, 0, 0.2)`, background: 'white',
      height: '35rem', width: '45rem', borderRadius: '0.5rem'
    }}
    >
      <Box sx={{
        fontWeight: '600', background: '#F2F2F2',
        borderRadius: '0.5rem', padding: '1rem', fontSize: '0.8rem',
        color: '#041e49',
      }}>New Message</Box>
      <Box>
        <Input placeholder="To: Selected Staffing vendors from the left menu" disabled
          sx={{ width: '100%', padding: '0.5rem 1rem', background: 'white' }} />
      </Box>
      <Box>
        <Input placeholder="Subject" value={subject} onChange={(e) => setSubject(e.target.value)}
          sx={{ width: '100%', padding: '0.5rem 1rem', background: 'white' }} />
      </Box>
      <ReactQuill
        value={mailContent}
        onChange={setMailContent}
        style={{ height: '19rem', color: 'black' }}
        modules={{
          toolbar: [
            [{ 'font': fonts.map(font => font.value) }],
            [{ 'size': ['small', false, 'large', 'huge'] }],
            ['bold', 'italic', 'underline'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'align': [] }],
            [{ 'color': [] }, { 'background': [] }],
            ['clean']
          ]
        }}
        formats={[
          'font',
          'size',
          'bold',
          'italic',
          'underline',
          'list',
          'bullet',
          'align',
          'color',
          'background'
        ]}
      />
      <Stack direction={'row'} gap={2} sx={{ position: 'absolute', left: '1rem', bottom: '0.8rem' }}>
        <ButtonComponent
          loading={loading}
          size='small' label='Send'
          onClick={() => sendMail()} sx={{ width: '6rem', height: '3rem', background: '#0b57d0', borderRadius: '5rem' }}
        />
        <label htmlFor="contained-button-file">
          {/*".pdf, .doc, .docx"*/}
          <Input1 accept=".pdf, .doc, .docx" id="contained-button-file" multiple type="file"
          onChange={(evt) => { setFileData(evt.target.files[0]); evt.target.value = '';} }/>
          {/* <AttachFile sx={{ marginLeft: '1rem', marginBottom: '-0.6rem', cursor: 'pointer', color: 'black' }} /> */}
          <Button variant="contained" sx={{ background: '#0b57d0' }} component="span">Upload Resume</Button>
          {fileData && <span style={{ marginLeft: '0.5rem', fontSize: '0.9rem', fontWeight: '600' }}>{fileData.name}</span>}
        </label>
      </Stack>
    </Stack>
  )

}
